import styled from "@emotion/styled";
import support from "../../../images/support.svg";
import center from "../../../images/developers_center.svg";
import sardine from "../../../images/developers_sardine.svg";
import media from "../../breakpoints";

const SupportWrapper = styled.section`
  .overlay {
    background: url(${support}) center/contain no-repeat;
    width: 3421px;
    height: 998px;
    transform: translate(31%, -14%);
  }
  .overlay1 {
    background: url(${center}) center/contain no-repeat;
    width: 1487px;
    height: 496px;
    transform: translate(-2%, 50%);
  }
  .overlay2 {
    background: url(${sardine}) center/contain no-repeat;
    width: 1217px;
    height: 182px;
    transform: translate(2%, -209%);
  }
  .main-title {
    font-size: 64px;
    font-weight: 600;
    text-align: left;
    line-height: 78px;
    ${media("md")} {
      font-size: 34px;
      font-weight: 600;
      text-align: center;
      line-height: 44px;
    }
  }
  .hint {
    max-width: 450px;
    font-weight: 400;
    font-size: 18px;
    text-align: left;
    ${media("md")} {
      font-size: 16px;
      font-weight: 400;
      text-align: center;
    }
  }
  div.form-wrapper {
    padding: 0;
  }
  .form-wrapper form.main-form::before,
  .form-wrapper form.main-form::after {
    display: none;
  }
  .form-wrapper form.main-form {
    min-width: 592px;
    min-height: 739px;
    ${media("md")} {
      min-width: auto;
    }
  }
  .form-wrapper form.main-form .check-label {
    display: none;
  }
  .form-submit {
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    min-width: 238px;
  }
`;

export { SupportWrapper };
