import React, { FC, useState } from "react";
import TopText from "../../topText/topText";
import ContactForm from "../../contactForm/contactForm";
import SuccessForm from "../../successForm/successForm";
import BlueSection from "../../blueSetion/blueSection";
import { SupportWrapper } from "./supportSection.styles";

interface Props {
  //   title: string;
}
const SupportSection: FC<Props> = () => {
  const topTextData = {
    title: "Need support?",
    subtitle:
      "Integrate our web JavaScript and mobile SDK in as little as one day. Supports iOS and Android. ",
  };
  const blueData = {
    textArr: ["Move money", " fast, ", "without risk."],
    text: "Book a demo",
  };
  const [showForm, setShowForm] = useState(true);
  return (
    <SupportWrapper className="section-wrapper">
      <div className="layout-width relative flex flex-row justify-between pt-20 md:flex-col md:mb-28 md:pt-0">
        <div className="overlay font-Inter absolute top-0 right-0 block"></div>
        <div className="overlay1 absolute top-0 left-0 block"></div>
        <div className="overlay2 absolute top-full left-0 block"></div>
        <TopText title={topTextData.title} subtitle={topTextData.subtitle} />
        {showForm ? (
          <ContactForm formName={"support"} setShowForm={setShowForm} />
        ) : (
          <SuccessForm />
        )}
      </div>
      <BlueSection textArr={blueData.textArr} btnText={blueData.text} />
    </SupportWrapper>
  );
};

export default SupportSection;
