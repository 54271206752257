import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo/seo";
import SupportSection from "../components/supportSections/supportSection/supportSection";

const SupportPage = () => {
  return (
    <Layout>
      <Seo title="Support" pathname="/support" />
      <main>
        <SupportSection />
      </main>
    </Layout>
  );
};

export default SupportPage;
